import { event } from 'jquery';
import '~/js/modules/fend';
/* eslint-disable */

const $optMeInCheckbox = $('#subscribe');


// create new array for countries and appending a new object that represents default value
const countries = [
    {
        name: 'Select A Country',
        id: '',
        regionCode: '',
        code: ''
    },
    ...window.beaches_app.page.countries
];

//console.log(countries.name);

$(document).ready(() => {
    countries.sort();
     // Populate the dropdown
  for (let i = 0; i < countries.length; i++) {
    $('#country').append(
      $('<option></option>')
        .val(countries[i].code)
        .html(countries[i].name)
    );
  }

});


$optMeInCheckbox.on('change', () => {
    $optMeInCheckbox.val($optMeInCheckbox.is(':checked'));
});

var news_form = document.getElementById("js-newsletter-form");
var thankyou_txt =document.getElementById("thankyou-txt");

document.getElementById("submit-button").onclick = function() {myFunction()};

function myFunction() {
    if (news_form.checkValidity()) {
      news_form.style.display='none'
      thankyou_txt.style.display='block'
    }
  }



// Form Validation
const $form = $('#js-newsletter-form');

$form.fend({
    rules: {
        fullName: 'lettersOnly',
        email: 'email',
        country: 'lettersOnly'
    }
});
