/* eslint-disable */

export default class gcvValidator {
    sugestAddress1 = '';
    sugestAddress2 = '';
    sugestAddress3 = '';
    sugestCountry = '';
    sugestState = '';
    sugestCity = '';
    sugestZip = '';
    suggestCounter = 0;
    emailAttempt = 0;
    TAemailAttempt = 0;
    initialMobile = '';
    initialLandline = '';
    mobileHasLandline = false;
    landlineHasMobile = false;
    mobileHasValid = false;
    landlineHasValid = false;
    TAinitialMobile = '';
    TAinitialLandline = '';
    TAmobileHasLandline = false;
    TAlandlineHasMobile = false;
    TAmobileHasValid = false;
    TAlandlineHasValid = false;
    timeout = '';
    submitForm = '';
    skipAddress = false;
    $form;
    gcvFields;
    hasGcvError = false;
    formNumber = 0;
    addressData = {};
    showAddressSuggestions = false;

    constructor($form, gcvFields, random) {
        // ReactDOM.render(
        //     <AddressSuggester gcvMethod={this.validateAddress} />,
        //     document.querySelector('#root')
        // );
        // return false;
        this.$form = $form;
        this.gcvFields = gcvFields;
        this.formNumber = random;

        this.mobileHasLandline = false;
        this.landlineHasMobile = false;
        this.mobileHasValid = false;
        this.landlineHasValid = false;
        const email = $('.gcvEmailField').val();
        if (getCookieValue('gcvEmailAttempt' + this.formNumber) !== undefined) {
            this.emailAttempt = parseInt(
                getCookieValue('gcvEmailAttempt' + this.formNumber)
            );
        }
        if (gcvFields.address === undefined) {
            this.skipAddress = true;
        }

        let validateEmail = true;
        let validateTAEmail = true;
        let validateMobile = true;
        let validateLandline = true;
        let validateAddress;

        if (gcvFields.email !== undefined) {
            validateEmail = this.emailValidator(email, false);
        }

        if (!validateEmail) {
            this.hasGcvError = true;
            var position = $('.gcv-error:first').offset().top - 320;
            $('html:not(:animated),body:not(:animated)').animate(
                {
                    scrollTop: position
                },
                1100
            );
            return false;
        }

        if (gcvFields.mobile !== undefined) {
            this.initialMobile = $('.gcvMobileField:visible').val();
            if (this.initialMobile !== '' && this.initialMobile !== undefined) {
                validateMobile = this.validateMobile(
                    this.initialMobile,
                    $('select.gcvCountryField').val(),
                    false
                );
            }
        }

        if (gcvFields.landline !== undefined) {
            this.initialLandline = $('.gcvLandlineField:visible').val();
            if (
                this.initialLandline !== '' &&
                this.initialLandline !== undefined
            ) {
                validateLandline = this.validateLandline(
                    this.initialLandline,
                    $('select.gcvCountryField').val(),
                    false
                );
            }
        }

        ///////////////////////////////////////////////////////////
        // FOR TA FIELDS (TAEMAIL, TAMOBILE, TALANDLINE) PRESENT IN
        // FORMS LIKE ENGAGEMENT CONCIERGE
        ///////////////////////////////////////////////////////////

        //IN CASE OF TA EMAIL
        if (
            gcvFields.TAemail !== undefined &&
            $('.travel-agent-information').hasClass('active')
        ) {
            if (
                getCookieValue('TAgcvEmailAttempt' + this.formNumber) !==
                undefined
            ) {
                this.TAemailAttempt = parseInt(
                    getCookieValue('TAgcvEmailAttempt' + this.formNumber)
                );
            }
            validateTAEmail = this.emailValidator(
                $('.gcvTaEmailField').val(),
                true
            );
        }

        if (!validateTAEmail) {
            this.hasGcvError = true;
            var position = $('.gcv-error:first').offset().top - 320;
            $('html:not(:animated),body:not(:animated)').animate(
                {
                    scrollTop: position
                },
                1100
            );
            return false;
        }

        //IN CASE OF TA MOBILE
        if (
            gcvFields.TAmobile !== undefined &&
            $('.travel-agent-information').hasClass('active')
        ) {
            this.TAinitialMobile = $('.gcvTaMobileField:visible').val();
            if (
                this.TAinitialMobile !== '' &&
                this.TAinitialMobile !== undefined
            ) {
                this.validateMobile(
                    this.TAinitialMobile,
                    $('select[name=taCountry] option:selected').data('code'),
                    true
                );
            }
        }

        //IN CASE OF TA LANDLINE
        if (
            gcvFields.TAlandline !== undefined &&
            $('.travel-agent-information').hasClass('active')
        ) {
            this.TAinitialLandline = $('.gcvTaLandlineField:visible').val();
            if (
                this.TAinitialLandline !== '' &&
                this.TAinitialLandline !== undefined
            ) {
                this.validateLandline(
                    this.TAinitialLandline,
                    $('select[name=taCountry] option:selected').data('code'),
                    true
                );
            }
        }

        ///////////////////////////////////////////////////////////
        //END TA FIELDS CODE
        ///////////////////////////////////////////////////////////

        if (
            gcvFields.mobile !== undefined ||
            gcvFields.landline !== undefined
        ) {
            this.phoneSorter();
        }

        if (gcvFields.address !== undefined) {
            validateAddress = this.validateAddress();
        }
    }

    emailValidator(email, taOrNo) {
        const $this = this;
        let emailValid = true;

        $.ajax({
            type: 'POST',
            async: false,
            url: '/emailValidator/',
            timeout: 20000,
            data: {
                email: email
            },
            cache: false,
            success: function(freturn) {
                const statusResponse = freturn.data.gcvResponse.STATUSNUMBER;

                console.log(statusResponse);
                console.log(freturn);
                /////////////////////////////////////
                //FOR NON TA EMAIL
                /////////////////////////////////////
                if (!taOrNo) {
                    //WHEN EMAIL IS RETIURNED AS INVALID
                    //NOTE THAT RESPONSE FOR VALID EMAIL IS '200'

                    if (statusResponse !== 200 && statusResponse !== 'ERROR') {
                        $this.emailAttempt = parseInt($this.emailAttempt) + 1;
                        console.log('emailAttempt' + $this.emailAttempt);
                        if ($this.emailAttempt < 2) {
                            $('.gcvEmailField').addClass('error');
                            $('.gcvEmailField').after(
                                '<label id="email-error" class="has-error gcv-error" for="email">Email not valid.</label> '
                            );
                            // $this.anchorAnimate();
                            emailValid = false;
                        } else {
                            $('#emailInvalid').val(true);
                            $('.gcvEmailField').removeClass('error');
                            $('.gcvEmailField')
                                .parent()
                                .find('.gcv-error')
                                .remove();
                            // $this.emailToNext();
                            emailValid = true;
                        }
                    }
                    //WHEN THERE IS AN ERROR IN THE SERVICE CALL OR TIMEOUT
                    //NOTE THAT RESPONSE FOR ERROR OR TIMEOUT IS 'ERROR'
                    else if (statusResponse == 'ERROR') {
                        $('#emailInvalid').val('ERROR');
                        $this.emailAttempt = 2;
                        $('.gcvEmailField').removeClass('error');
                        $('.gcvEmailField')
                            .parent()
                            .find('.gcv-error')
                            .remove();
                        emailValid = true;
                    } else {
                        $('#emailInvalid').val(false);
                        $('.gcvEmailField').removeClass('error');
                        $('.gcvEmailField')
                            .parent()
                            .find('.gcv-error')
                            .remove();
                        emailValid = true;
                    }
                } else {
                    /////////////////////////////////////
                    //FOR  TA EMAIL
                    /////////////////////////////////////
                    if (statusResponse !== 200 && statusResponse !== 'ERROR') {
                        $this.TAemailAttempt =
                            parseInt($this.TAemailAttempt) + 1;
                        console.log('TAemailAttempt' + $this.TAemailAttempt);
                        if ($this.TAemailAttempt < 2) {
                            $('.gcvTaEmailField').addClass('error');
                            $('.gcvTaEmailField').after(
                                '<label id="email-error" class="has-error gcv-error" for="email">Email not valid.</label> '
                            );
                            // $this.anchorAnimate();
                            emailValid = false;
                        } else {
                            $('#taemailInvalid').val(true);
                            $('.gcvTaEmailField').removeClass('error');
                            $('.gcvTaEmailField')
                                .parent()
                                .find('.gcv-error')
                                .remove();
                            // $this.emailToNext();
                            emailValid = true;
                        }
                    }
                    //WHEN THERE IS AN ERROR IN THE SERVICE CALL OR TIMEOUT
                    //NOTE THAT RESPONSE FOR ERROR OR TIMEOUT IS 'ERROR'
                    else if (statusResponse == 'ERROR') {
                        $('#taemailInvalid').val('ERROR');
                        $this.TAemailAttempt = 2;
                        $('.gcvTaEmailField').removeClass('error');
                        $('.gcvTaEmailField')
                            .parent()
                            .find('.gcv-error')
                            .remove();
                        emailValid = true;
                    } else {
                        $('#taemailInvalid').val(false);
                        $('.gcvTaEmailField').removeClass('error');
                        $('.gcvTaEmailField')
                            .parent()
                            .find('.gcv-error')
                            .remove();
                        emailValid = true;
                    }
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                $('#emailInvalid').val('ERROR');
                $this.emailAttempt = 2;
                $('.gcvEmailField').removeClass('error');
                $('.gcvEmailField')
                    .parent()
                    .find('.gcv-error')
                    .remove();
                emailValid = true;
            }
        });

        let date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);
        if (!taOrNo) {
            document.cookie =
                'gcvEmailAttempt' +
                this.formNumber +
                ' = ' +
                $this.emailAttempt +
                '; domain=.beaches.co.uk;path=/; expires=' +
                date;
        } else {
            document.cookie =
                'TAgcvEmailAttempt' +
                this.formNumber +
                ' = ' +
                $this.TAemailAttempt +
                '; domain=.beaches.co.uk;path=/; expires=' +
                date;
        }

        return emailValid;
    }

    validateMobile(number, country, taOrNo) {
        const $this = this;

        $.ajax({
            type: 'GET',
            async: false,
            url: '/phoneValidator/',
            timeout: 20000,
            data: {
                phoneN: number,
                CntCode: country,
                inputType: 'mobile'
            },
            cache: false,
            success: function(freturn) {
                //NOTE THAT RESPONSE FOR VALID LANDLINE IS '201 LandLine'
                //NOTE THAT RESPONSE FOR VALID MOBILE IS '201 Mobile'
                //FOR THE ERROR OR TIMEOUT IN THE SERVICE THE REPONSE IS 'ERROR'
                const statusResponse =
                    freturn.data.gcvResponse.STATUSNUMBER +
                    ' ' +
                    freturn.data.gcvResponse.PHONENUMBERTYPE;

                if (!taOrNo) {
                    /////////////////////////////////////
                    //FOR NON TA MOBILE
                    /////////////////////////////////////
                    if ($.trim(statusResponse) == '201 LandLine') {
                        $this.mobileHasLandline = true;
                    } else if ($.trim(statusResponse) == '201 Mobile') {
                        $('.gcvMobileField').removeClass('error');
                        $this.mobileHasValid = true;
                        $this.mobileHasLandline = false;
                    } else if ($.trim(statusResponse) == 'ERROR') {
                        $('.gcvMobileField').removeClass('error');
                        $('#mobileInvalid').val('ERROR');
                        $this.mobileHasValid = true;
                        $this.mobileHasLandline = false;
                    } else {
                        $this.mobileHasLandline = false;
                        $this.mobileHasValid = false;
                        $('#mobileInvalid').val(false);
                    }
                } else {
                    /////////////////////////////////////
                    //FOR TA MOBILE
                    /////////////////////////////////////
                    if ($.trim(statusResponse) == '201 LandLine') {
                        $this.TAmobileHasLandline = true;
                    } else if ($.trim(statusResponse) == '201 Mobile') {
                        $('.gcvTaMobileField').removeClass('error');
                        $this.TAmobileHasValid = true;
                        $this.TAmobileHasLandline = false;
                    } else if ($.trim(statusResponse) == 'ERROR') {
                        $('.gcvTaMobileField').removeClass('error');
                        $('#tamobileInvalid').val('ERROR');
                        $this.TAmobileHasValid = true;
                        $this.TAmobileHasLandline = false;
                    } else {
                        $this.TAmobileHasLandline = false;
                        $this.TAmobileHasValid = false;
                        $('#tamobileInvalid').val(false);
                    }
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                if (textStatus === 'timeout') {
                    $('.gcvMobileField').removeClass('error');
                    $('#mobileInvalid').val('ERROR');
                    $this.mobileHasValid = true;
                    $this.mobileHasLandline = false;
                } else {
                    console.log(errorThrown);
                }
            }
        });

        return true;
    }

    //LANDLINE VALIDATION METHOD
    validateLandline(number, country, taOrNo) {
        const $this = this;

        if (number !== '') {
            $.ajax({
                type: 'GET',
                async: false,
                url: '/phoneValidator/',
                timeout: 20000,
                data: {
                    phoneN: number,
                    CntCode: country,
                    inputType: 'landline'
                },
                cache: false,
                success: function(freturn) {
                    //NOTE THAT RESPONSE FOR VALID LANDLINE IS '201 LandLine'
                    //NOTE THAT RESPONSE FOR VALID MOBILE IS '201 Mobile'
                    //FOR THE ERROR OR TIMEOUT IN THE SERVICE THE REPONSE IS 'ERROR'

                    const statusResponse =
                        freturn.data.gcvResponse.STATUSNUMBER +
                        ' ' +
                        freturn.data.gcvResponse.PHONENUMBERTYPE;

                    console.log(statusResponse);

                    if (!taOrNo) {
                        /////////////////////////////////////
                        //FOR NON TA LANDLINE
                        /////////////////////////////////////
                        if ($.trim(statusResponse) == '201 LandLine') {
                            $this.landlineHasValid = true;
                            $('.gcvLandlineField').removeClass('error');
                            $this.landlineHasMobile = false;
                        } else if ($.trim(statusResponse) == '201 Mobile') {
                            $this.landlineHasMobile = true;
                        } else if ($.trim(statusResponse) == 'ERROR') {
                            $this.landlineHasValid = true;
                            $('#landLineInvalid').val('ERROR');
                            $('.gcvLandlineField').removeClass('error');
                            $this.landlineHasMobile = false;
                        } else {
                            $this.landlineHasValid = false;
                            $this.landlineHasMobile = false;
                            $('#landLineInvalid').val(false);
                        }
                        $('.gcvLandlineField').removeClass('error');
                    } else {
                        if ($.trim(statusResponse) == '201 LandLine') {
                            $this.TAlandlineHasValid = true;
                            $('.gcvTaLandlineField').removeClass('error');
                            $this.TAlandlineHasMobile = false;
                        } else if ($.trim(statusResponse) == '201 Mobile') {
                            $this.TAlandlineHasMobile = true;
                        } else if ($.trim(statusResponse) == 'ERROR') {
                            $this.TAlandlineHasValid = true;
                            $('#talandLineInvalid').val('ERROR');
                            $('.gcvTaLandlineField').removeClass('error');
                            $this.TAlandlineHasMobile = false;
                        } else {
                            $this.TAlandlineHasValid = false;
                            $this.TAlandlineHasMobile = false;
                            $('#talandLineInvalid').val(false);
                        }
                        $('.gcvTaLandlineField').removeClass('error');
                    }
                },
                error: function(jqXHR, textStatus, errorThrown) {
                    /////////////////////////////////////
                    //FOR TA LANDLINE
                    /////////////////////////////////////
                    if (textStatus === 'timeout') {
                        $this.landlineHasValid = true;
                        $('#landLineInvalid').val('ERROR');
                        $('.gcvLandlineField').removeClass('error');
                        $this.landlineHasMobile = false;
                    } else {
                        console.log(errorThrown);
                    }
                }
            });
        }
        return true;
    }

    phoneSorter() {
        console.log(
            this.landlineHasMobile,
            this.mobileHasLandline,
            this.landlineHasValid,
            this.mobileHasValid
        );

        if (this.landlineHasMobile && this.mobileHasLandline) {
            this.$form.find('.gcvMobileField').val(this.initialLandline);
            this.$form.find('.gcvLandlineField').val(this.initialMobile);
        } else if (this.landlineHasMobile && !this.mobileHasValid) {
            this.$form.find('.gcvMobileField').val(this.initialLandline);
            this.$form.find('.gcvLandlineField').val('');
        } else if (this.landlineHasMobile && this.mobileHasValid) {
            this.$form.find('.gcvLandlineField').val('');
            this.$form.find('#mobile2').val(this.initialLandline);
        } else if (this.mobileHasLandline && !this.landlineHasValid) {
            this.$form.find('.gcvLandlineField').val(this.initialMobile);
            this.$form.find('.gcvMobileField').val('');
        } else if (this.mobileHasLandline && this.landlineHasValid) {
            this.$form.find('.gcvMobileField').val('');
            this.$form.find('#landline2').val(this.initialMobile);
        } else if (!this.landlineHasValid && this.mobileHasValid) {
            this.$form.find('.gcvLandlineField').val('');
        } else if (this.landlineHasValid && !this.mobileHasValid) {
            this.$form.find('.gcvMobileField').val('');
        } else if (!this.landlineHasValid && !this.mobileHasValid) {
            this.$form.find('.gcvLandlineField').val('');
            this.$form.find('.gcvMobileField').val('');
        } else {
        }

        console.log(
            this.TAlandlineHasMobile,
            this.TAmobileHasLandline,
            this.TAlandlineHasValid,
            this.TAmobileHasValid
        );

        /////////////////////////////////////
        //FOR  TA NUMBERS
        /////////////////////////////////////
        if (this.TAlandlineHasMobile && this.TAmobileHasLandline) {
            $('.gcvTaMobileField').val(this.TAinitialLandline);
            $('.gcvTaLandlineField').val(this.TAinitialMobile);
        } else if (this.TAlandlineHasMobile && !this.TAmobileHasValid) {
            $('.gcvTaMobileField').val(this.TAinitialLandline);
            $('.gcvTaLandlineField').val('');
        } else if (this.TAlandlineHasMobile && this.TAmobileHasValid) {
            $('.gcvTaLandlineField').val('');
            $('#tamobile2').val(this.TAinitialLandline);
        } else if (this.TAmobileHasLandline && !this.TAlandlineHasValid) {
            $('.gcvTaLandlineField').val(this.TAinitialMobile);
            $('.gcvTaMobileField').val('');
        } else if (this.TAmobileHasLandline && this.TAlandlineHasValid) {
            console.log('MOBILE HAS LANDLINE');
            $('.gcvTaMobileField').val('');
            $('#talandline2').val(this.TAinitialMobile);
        } else if (!this.TAlandlineHasValid && this.TAmobileHasValid) {
            $('.gcvTaLandlineField').val('');
        } else if (this.TAlandlineHasValid && !this.TAmobileHasValid) {
            $('.gcvTaMobileField').val('');
        } else if (!this.TAlandlineHasValid && !this.TAmobileHasValid) {
            $('.gcvTaLandlineField').val('');
            $('.gcvTaMobileField').val('');
        } else {
        }
    }

    validateAddress() {
        let $address;
        let $address2;
        let $country;
        let $state;
        let $otherState;
        let $city;
        let $zip;
        let addressValid = false;
        const $this = this;

        if ($('.gcvAddressField1').is(':visible')) {
            $address = $.trim($('.gcvAddressField1').val());
        }
        if ($('.gcvAddressField2').is(':visible')) {
            $address2 = $.trim($('.gcvAddressField2').val());
        }
        $country = $.trim($('select.gcvCountryField').val());
        $state = $.trim($('select.gcvStateField').val());
        if ($('.gcvStateFieldOther').is(':visible')) {
            $otherState = $.trim($('.gcvStateFieldOther').val());
        }
        if ($('.gcvCityField').is(':visible')) {
            $city = $.trim($('.gcvCityField').val());
        }
        if ($('.gcvZipField').is(':visible')) {
            $zip = $.trim($('.gcvZipField').val()).toUpperCase();
        }

        const inputCountry = $country;
        let inputState = '';

        if ($country == 'US' || $country == 'GB' || $country == 'CA') {
            inputState = $state;
        } else {
            inputState = encodeURIComponent($otherState);
        }

        $.ajax({
            type: 'POST',
            async: false,
            url: '/addressValidator/',
            data: {
                street1: $address,
                street2: $address2,
                street3: '',
                country: inputCountry,
                state: inputState,
                city: $city,
                suite: '',
                zipcode: $zip
            },
            timeout: 20000,
            cache: false,
            success: function(freturn) {
                console.log(freturn);
                freturn = freturn.data.gcvResponse;
                console.log(freturn.RESPONSE_RESULTS.SUCCESS.toUpperCase());
                $this.addressData = freturn;

                if (
                    freturn.RESPONSE_RESULTS.SUCCESS.toUpperCase() === 'FALSE'
                ) {
                    $('#inputAddr').val(
                        decodeURIComponent(
                            freturn.INPUT_ARGUMENTS.FORMATTEDADDR
                        )
                    );
                    $('#isgcvlog').val(true);

                    $this.showAddressSuggestions = false;
                } else if (
                    freturn.RESPONSE_RESULTS.SUCCESS.toUpperCase() == 'ERROR'
                ) {
                    $('#inputAddr').val(
                        decodeURIComponent(
                            freturn.INPUT_ARGUMENTS.FORMATTEDADDR
                        )
                    );
                    $('#isgcvlog').val('ERROR');
                    $this.showAddressSuggestions = false;
                } else if (
                    freturn.RESPONSE_RESULTS.SUCCESS.toUpperCase() === 'TRUE' &&
                    freturn.RESPONSE_RESULTS.SHOWSUGGESTEDADDR.toUpperCase() ===
                        'TRUE'
                ) {
                    $('#inputAddr').val(freturn.INPUT_ARGUMENTS.FORMATTEDADDR);
                    $('#mdAddr').val(freturn.FORMATTEDADDRESS.FORMATTEDADDRESS);

                    $this.showAddressSuggestions = true;
                } else if (
                    freturn.RESPONSE_RESULTS.SUCCESS &&
                    !freturn.RESPONSE_RESULTS.SHOWSUGGESTEDADDR
                ) {
                    $this.showAddressSuggestions = false;
                }
            },
            error: function(jqXHR, textStatus, errorThrown) {
                if (textStatus === 'timeout') {
                    $('#inputAddr').val(
                        decodeURIComponent(
                            freturn.INPUT_ARGUMENTS.FORMATTEDADDR
                        )
                    );
                    $('#isgcvlog').val('ERROR');
                    $this.showAddressSuggestions = false;
                } else {
                    console.log(errorThrown);
                }
            }
        });
    }
}

window.getCookieValue = function(name) {
    var value = '; ' + document.cookie;
    var parts = value.split('; ' + name + '=');
    if (parts.length == 2)
        return parts
            .pop()
            .split(';')
            .shift();
};
