/* eslint-disable */
/**
    taken from sandals mobile, kudos to Luis Matute for this
*/
/** `internals` defines a local namespace and defaults for this module. */
const internals = {};

/** Crossbrowser css events */
internals.animEvts = 'webkitAnimationEnd oAnimationEnd MSAnimationEnd animationend transitionend';

/** Default options, will be overridden on instantiation */
internals.defaults = {
  // Element to which append the notification
  target: 'body',

  // The Message
  message: 'yo!',

  // Notification type: success|error|warning|info
  type: 'error',

  // if the user doesn't close the notification then we remove it
  // after the following time
  ttl: 6000,

  // Callbacks
  onClose: () => false,
  onOpen: () => false
};

/** Creates HTML template needed, will be appended to $target */
internals._initTpl = (self) => {
  const $inner = $('<div />', {
    'class': 'ntf-inner'
  });
  let $target = $(self.settings.target);

  $inner.html(self.settings.message);
  self.$ntf = $('<div />', {
    'class': `ntf-box ntf-${self.settings.type}`
  });
  self.$ntf
    .append($('<button />', {
      'class': 'ntf-close icon-close'
    }))
    .append($inner);

  // Append new notification to the $target
  $target.prepend(self.$ntf);
  self.active = true;
  self.show(self);

  // Dismiss after [settings.ttl]ms
  if (self.settings.ttl !== 0) {
    self.dismissttl = setTimeout(() => {
      if (self.active) {
        self.dismiss(self);
      }
    }, self.settings.ttl);
  }
};

/** Creates the events listeners for the notifications */
internals._initEvents = (self) => {
  let startX;
  let startY;
  let dist;
  const threshold = 150; //required min distance traveled to be considered swipe
  const allowedTime = 200; // maximum time allowed to travel that distance
  let elapsedTime;
  let startTime;
  let touchobj;
  let pageX;
  let pageY;
  let swiperightBol;

  self.$ntf.on('click touchstart', '.ntf-close', (evt) => {

    evt.preventDefault();
    self.dismiss(self);
  });

  self.$ntf.on('mousedown touchstart', (evt) => {

    touchobj = ('changedTouches' in evt.originalEvent) ? evt.originalEvent.changedTouches[0] : {};
    dist = 0;
    startX = ('pageX' in evt) ? evt.pageX : touchobj.pageX;
    startY = ('pageY' in evt) ? evt.pageY : touchobj.pageY;
    startTime = new Date().getTime(); // record time when finger first makes contact with surface
    evt.preventDefault();
  });

  self.$ntf.on('mousemove touchmove', (evt) => {

    evt.preventDefault();
  });

  self.$ntf.on('mouseup touchend', (evt) => {

    touchobj = ('changedTouches' in evt.originalEvent) ? evt.originalEvent.changedTouches[0] : {};
    pageX = ('pageX' in evt) ? evt.pageX : touchobj.pageX;
    pageY = ('pageY' in evt) ? evt.pageY : touchobj.pageY;

    dist = pageX - startX; // get total dist traveled by finger while in contact with surface
    elapsedTime = new Date().getTime() - startTime; // get time elapsed
    // check that elapsed time is within specified, horizontal dist traveled >= threshold, and vertical dist traveled <= 100
    swiperightBol = (elapsedTime <= allowedTime && dist >= threshold && Math.abs(pageY - startY) <= 100);

    if (swiperightBol) {
      self.dismiss(self, 'right');
    }
    evt.preventDefault();
  });
};

/**
 * @class
 * Creates a new notification(Avis).
 */
export default class Avis {
  /**
  * Class constructor
  * @param {Object} settings - Overwrites internals
      {
          $target: $() - jQuery object of where to prepend the notification
          message: {string},
          type: {('error'|'warning'|'success'|'info')},
          ttl: {number}, // length visible. 0 means visible until clicking the 'x',
          onClose: {function}, // event when fired when closing the notification
          onOpen: {function} // event when fired before showing the notification
      }
  */
  constructor(settings) {

    try {
      this.settings = Object.assign({}, internals.defaults, settings);
      internals._initTpl(this);
      internals._initEvents(this);
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Show the notification
   */
  show(avis) {

    avis.active = true;
    avis.$ntf
      .removeClass('ntf-hide')
      .addClass('ntf-show');
    avis.settings.onOpen();
  }

  /**
   * Dismiss the notification
   */
  dismiss(avis, hideClass) {

    // console.log(arguments);
    const onAnimEnd = (evt) => {

      if (evt.target !== avis.$ntf[0]) {
        return false;
      }

      avis.$ntf.remove();
    };

    hideClass = hideClass || 'hide';
    clearTimeout(avis.dismissttl);
    avis.active = false;
    avis.$ntf.removeClass('ntf-show');
    avis.$ntf.addClass(`ntf-${hideClass}`);
    avis.settings.onClose();

    // Appending Event Listener for CSS animations
    avis.$ntf.one(internals.animEvts, onAnimEnd);
  }
}