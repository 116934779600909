import PropTypes from 'prop-types';
/* eslint-disable */
const { Component } = React;
let timeout;

export default class AddressSuggester extends Component {
    static propTypes = {};

    static defaultProps = {
        data: PropTypes.object,
        submitMethod: PropTypes.func,
        action: PropTypes.string,
        main: PropTypes.func
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const $this = this;
        this.loadingAnimationHandler(false);
        $('html').addClass('html-no-scroll');
        timeout = setInterval(function(e) {
            $this.submitOriginal(e);
        }, 30000);
        console.log(this.props.data);
    }

    // Methods to submit from the address suggestion lightbox
    submitSuggested = function(e) {
        e.preventDefault();
        $('#isgcvlog').val(false);

        const sugestAddress1 = decodeURIComponent(
            this.props.data.PROPOSED_ARGUMENTS.ADDRESSLINE1
        );

        const sugestState = decodeURIComponent(
            this.props.data.PROPOSED_ARGUMENTS.STATE
        );
        const sugestCity = decodeURIComponent(
            this.props.data.PROPOSED_ARGUMENTS.CITY
        );
        const sugestZip = decodeURIComponent(
            this.props.data.PROPOSED_ARGUMENTS.POSTALCODE
        );
        if (
            $('select.gcvCountryField').val() != 'US' &&
            $('select.gcvCountryField').val() != 'GB' &&
            $('select.gcvCountryField').val() != 'CA'
        ) {
            $('.gcvStateFieldOther').val(sugestState);
        } else {
            $('select.gcvStateField option').each(function(index, element) {
                var text = $(this).text();
                var opVal = $(this).attr('value');

                if (
                    text.toUpperCase() == sugestState.toUpperCase() ||
                    opVal.toUpperCase() == sugestState.toUpperCase()
                ) {
                    $('select.gcvStateField')
                        .val(opVal)
                        .trigger('change');
                }
            });
        }
        $('.gcvAddressField1').val(sugestAddress1);
        $('.gcvCityField').val(sugestCity);
        $('.gcvZipField').val(sugestZip);
        $('#isgcvlog').val(false);
        this.loadingAnimationHandler(true);
        this.props.submitMethod(this.props.action, true, this.props.main);
        this.unmountAddressSuggester(e);

        return false;
    };

    unmountAddressSuggester = function(e) {
        this.loadingAnimationHandler(false);
        clearInterval(timeout);
        ReactDOM.unmountComponentAtNode(document.querySelector('#root'));
        if (e !== undefined) {
            e.preventDefault();
        }
        this.loadingAnimationHandler(true);
        return false;
    };

    unmountAddressSuggesterNoLoader = function(e) {
        this.loadingAnimationHandler(false);
        clearInterval(timeout);
        ReactDOM.unmountComponentAtNode(document.querySelector('#root'));
        if (e !== undefined) {
            e.preventDefault();
        }
        return false;
    };

    //SUBMIT ORIGINALLY ENTERED ADDRESS FROM LIGHBOX
    submitOriginal = function(e) {
        if (e !== undefined) {
            e.preventDefault();
        }
        $('#isgcvlog').val(true);
        this.props.submitMethod(this.props.action, true, this.props.main);
        this.unmountAddressSuggester(e);
        return false;
    };

    loadingAnimationHandler = function(displayLoader = true) {
        const $loader = $('.beaches-loader');
        const $html = $('html');
        if (displayLoader) {
            $loader.removeClass('inactive').addClass('submitting-post');
            $html.addClass('html-no-scroll');
        } else {
            $loader.addClass('inactive').removeClass('submitting-post');
            $html.removeClass('html-no-scroll');
        }
    };

    render() {
        return (
            <div className="address-suggester-modal-background">
                <div
                    className="addressSuggestions noClose"
                    id="addressSuggestions"
                >
                    <div className="overlayUndo" />
                    <h3>Address Verification</h3>
                    <p>
                        We are unable to verify your address as entered, but
                        found a close match. Please confirm your correct
                        address, or return to the{' '}
                        <a
                            className="returnToForm"
                            href=""
                            onClick={e => {
                                this.unmountAddressSuggesterNoLoader(e);
                            }}
                        >
                            form
                        </a>{' '}
                        to edit your entry.
                    </p>
                    <div className="contentAdd xs-12 row">
                        <div className="addLeft xs-12 md-12 lg-6">
                            <div>
                                <h4>You Entered:</h4>

                                <p>
                                    <span>
                                        {
                                            this.props.data.INPUT_ARGUMENTS
                                                .ADDRESSLINE1
                                        }
                                    </span>
                                    <span>
                                        {this.props.data.INPUT_ARGUMENTS.CITY},
                                    </span>
                                    <span>
                                        {this.props.data.INPUT_ARGUMENTS.STATE},
                                    </span>
                                    <span>
                                        {
                                            this.props.data.INPUT_ARGUMENTS
                                                .COUNTRY
                                        }{' '}
                                        {
                                            this.props.data.INPUT_ARGUMENTS
                                                .POSTALCODE
                                        }
                                    </span>
                                </p>
                                <a
                                    href=""
                                    className="blackButton submitSuggestedWindow"
                                    onClick={e => {
                                        this.submitOriginal(e);
                                    }}
                                >
                                    Use this Address
                                </a>
                            </div>
                        </div>
                        <div className="addRight xs-12 md-12 lg-6">
                            <div>
                                <h4>Suggested Address:</h4>

                                <p>
                                    <span>
                                        {
                                            this.props.data.PROPOSED_ARGUMENTS
                                                .ADDRESSLINE1
                                        }
                                    </span>
                                    <span>
                                        {
                                            this.props.data.PROPOSED_ARGUMENTS
                                                .CITY
                                        }
                                        ,
                                    </span>
                                    <span>
                                        {
                                            this.props.data.PROPOSED_ARGUMENTS
                                                .STATE
                                        }
                                        ,
                                    </span>
                                    <span>
                                        {
                                            this.props.data.PROPOSED_ARGUMENTS
                                                .COUNTRY
                                        }{' '}
                                        {
                                            this.props.data.PROPOSED_ARGUMENTS
                                                .POSTALCODE
                                        }
                                    </span>
                                </p>
                                <a
                                    href=""
                                    className="blackButton submitSuggestedWindow"
                                    onClick={e => {
                                        this.submitSuggested(e);
                                    }}
                                >
                                    {' '}
                                    Use this Address
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
